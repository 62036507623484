import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import {
  trackSwitchCountryVisible,
  trackCountryNavigation,
  trackStayInCountry,
  trackCloseSwitchCountry,
} from "./track-switch-country";

// TODO: This should become part of the UI state and
// we should transform Overlay to a component in our library
const triggerOverlay = (action: "open" | "close") => {
  const overlay = document.querySelector(".Overlay");

  switch (action) {
    case "open":
      document.body.classList.add("locked");
      overlay.setAttribute("data-open", "true");
      return;
    case "close":
      document.body.classList.remove("locked");
      overlay.setAttribute("data-open", "false");
      return;
    default:
      document.body.classList.toggle("locked");
      overlay.setAttribute(
        "data-open",
        overlay.getAttribute("data-open") === "true" ? "false" : "true"
      );
      return;
  }
};

export const useSwitchCountry = ({ marketplace }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleNavigateToCountry = useCallback(() => {
    window.location.href = "/choose-your-country";
    triggerOverlay("close");
    setOpenDialog(false);
    trackCountryNavigation();
  }, []);

  const handleClose = useCallback(() => {
    triggerOverlay("close");
    trackCloseSwitchCountry();
    setOpenDialog(false);
  }, []);

  useEffect(() => {
    const checkAndOpenDialog = async () => {
      try {
        // Check for persistent cookie and session override
        const persistentOverride = Cookies.get("locale_user_override_always");
        const sessionOverride = sessionStorage.getItem(
          "locale_user_override_session"
        );

        // If either is set, do not open the modal
        if (persistentOverride || sessionOverride) {
          return;
        }

        // Check if user is on the homepage
        if (window.location.pathname === "/") {
          const response = await fetch("/geo");
          const geoData = await response.json();

          // Open modal if the marketplace is US and the user is not in the US
          if (marketplace === "us" && !geoData.isUS) {
            sessionStorage.setItem("locale_user_override_session", "true"); // Set session override
            trackSwitchCountryVisible();
            triggerOverlay("open");
            setOpenDialog(true);
          }
        }
      } catch (error) {
        console.error("Error fetching geolocation data:", error);
      }
    };

    checkAndOpenDialog();
  }, [marketplace]);

  const handleStayInCountry = useCallback(() => {
    // Set persistent override cookie
    Cookies.set("locale_user_override_always", "true", { expires: 60 });
    trackStayInCountry();

    // I'm not using handleClose directly here to avoid
    // re-triggering the trackCloseSwitchCountry event
    setTimeout(() => setOpenDialog(false), 300);
  }, []);

  return {
    openDialog,
    handleClose,
    handleNavigateToCountry,
    handleStayInCountry,
  };
};
