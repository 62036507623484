import type { FC } from "react";
import { Button } from "@wbly/ui";
import type { ImageField } from "@prismicio/client";
import style from "./Hero.module.css";
import wrapperStyle from "./ContentWrapper.module.css";
import "../../style/Themes.module.css";

const { slide, content, image: imageSt, buttonSt } = style;
const { contentWrapper } = wrapperStyle;

type SlideProps = {
  title: string;
  description: string;
  placeholder?: boolean;
  children?: React.ReactNode;
  image: {
    desktop: ImageField | null;
    mobile: ImageField | null;
  };
  theme: string;
  cutout?: string;
  ctaLabel?: string;
  url?: string;
};

export const Slide: FC<SlideProps> = (props) => {
  const {
    children,
    title,
    description,
    placeholder,
    image,
    theme,
    cutout,
    ctaLabel,
    url,
  } = props;

  return (
    <div className={slide} data-placeholder={placeholder}>
      <div className={contentWrapper} data-cutout={cutout} data-theme={theme}>
        <div className={content} data-theme={theme}>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        {ctaLabel && url && (
          <Button as="a" href={url} variant="primary" className={buttonSt}>
            {ctaLabel}
          </Button>
        )}
      </div>
      <div className={imageSt}>
        {children ? (
          children
        ) : (
          <img
            src={image.mobile.url} /* Default image for small screens */
            srcSet={`
              ${image.mobile.url} 480w,
              ${image.desktop ? image.desktop.url : image.mobile.url} 1200w
            `}
            sizes="(min-width: 777px) 100vw, 50vw"
            alt={title}
          />
        )}
      </div>
    </div>
  );
};
