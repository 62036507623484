import { useEffect } from "react";

// Initializes the cutout element and handles resizing logic
const initializeCutout = (cutout, invert, id) => {
  const updateCutout = () => {
    const windowSize = window.innerWidth;
    const cutoutDesktopPosition = invert ? "right" : "left";
    const element = document.querySelector(`[data-cutoutid="${id}"]`);

    if (element) {
      const cutoutValue =
        windowSize < 1024
          ? `${cutout} top`
          : `${cutout} ${cutoutDesktopPosition}`;
      element.setAttribute("data-cutout", cutoutValue);
    }
  };

  // Initial update and event listener setup
  updateCutout();
  window.addEventListener("resize", updateCutout);

  // Cleanup function to remove event listener
  return () => {
    window.removeEventListener("resize", updateCutout);
  };
};

type UseBrandHeroProps = {
  cutout: string;
  invert: boolean;
  id: string;
  url: string;
};

// Custom hook to manage brand hero cutout behaviour
const useBrandHero = ({ cutout, invert, id }: UseBrandHeroProps) => {
  useEffect(() => {
    const handleCutoutOrientation = initializeCutout(cutout, invert, id);
    return handleCutoutOrientation;
  }, [cutout, invert, id]);
};

export default useBrandHero;
