// src/client.sublimator.ts
import { cookie } from "@prismicio/client";

// src/client.ts
import { ZodError } from "zod";
import { stringify } from "@wbly/common";

// src/errors.ts
var ClientError = class _ClientError extends Error {
  constructor(options) {
    super(options.message, { cause: options.cause });
  }
  static Unknown(e, url) {
    const error = e;
    return new _ClientError({
      code: "UNKNOWN",
      message: `An unknown error occured: ${error.message}`,
      url,
      cause: error.cause
    });
  }
  static ContentTypeError(response, text) {
    return new _ClientError({
      code: "INVALID_CONTENT_TYPE",
      message: `Invalid content type returned. Expected: "application/json"; received: "${response.headers.get(
        "Content-Type"
      )}"
Response: "${text}"`,
      url: response.url
    });
  }
  static SyntaxError(e, url) {
    return new _ClientError({
      code: "SYNTAX_ERROR",
      message: `Error while parsing JSON response: ${e.message}`,
      cause: e.cause,
      url
    });
  }
};
var NetworkError = class extends ClientError {
  constructor(e, url) {
    super({
      code: "NETWORK_ERROR",
      message: `A network error occured while connecting to ${url}`,
      url,
      cause: e.cause
    });
  }
};
var HttpError = class extends ClientError {
  response;
  error;
  constructor(response, { message, error }) {
    super({
      message: `HTTP ${response.statusText}: ${message} 
URL: ${response.url}`,
      code: `HTTP_${response.status}`,
      url: response.url
    });
    this.response = response;
    this.error = error;
  }
};
var ResponseValidationError = class extends ClientError {
  error;
  constructor(error, url, json) {
    super({
      message: `Unexpected response received from API: 
Response:${json} 
Errors:${error.message}`,
      code: "RESPONSE_INVALID",
      url,
      cause: error.cause
    });
    this.error = error;
  }
};

// src/util.ts
function isTypeError(e) {
  return e instanceof TypeError;
}
function isSyntaxError(e) {
  return e instanceof SyntaxError;
}

// src/client.ts
var ApiClient = class {
  baseURL;
  headers;
  cookies;
  constructor(options) {
    if (!options.baseURL) {
      throw new Error("Cannot create API Client without base URL");
    }
    this.headers = {};
    this.cookies = {};
    if (options.apiKey) {
      this.headers.Authorization = `Bearer ${options.apiKey}`;
    }
    this.baseURL = options.baseURL;
  }
  setHeader(key, value) {
    if (!value)
      return;
    this.headers[key] = value;
  }
  setCookie(key, value) {
    if (!value)
      return;
    this.cookies[key] = value;
  }
  url(endpoint, query = {}) {
    return `${this.baseURL}/${endpoint}${stringify(query, true)}`;
  }
  async request(requestUrl, init) {
    const cookies = Object.entries(this.cookies).reduce((str, [key, value]) => {
      return `${str}${key}=${value}; `;
    }, "");
    try {
      return await fetch(requestUrl, {
        headers: { ...this.headers, Cookie: cookies },
        mode: "cors",
        ...init
      });
    } catch (e) {
      if (isTypeError(e)) {
        throw new NetworkError(e, requestUrl);
      }
      throw ClientError.Unknown(e, requestUrl);
    }
  }
  async parseResponse(requestUrl, response) {
    if (!response.headers.get("Content-Type")?.includes("application/json")) {
      const text = await response.text();
      throw ClientError.ContentTypeError(response, text);
    }
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
        const details = this.validateErrorResponse(json);
        throw new HttpError(response, details);
      } catch (e) {
        if (e instanceof HttpError) {
          throw e;
        }
        if (e instanceof ZodError) {
          throw new ResponseValidationError(
            e,
            requestUrl,
            JSON.stringify(json)
          );
        }
        if (isSyntaxError(e)) {
          throw ClientError.SyntaxError(e, requestUrl);
        }
        throw ClientError.Unknown(e, requestUrl);
      }
    }
  }
  async serializeResponseBody(requestUrl, response, schema) {
    let json;
    try {
      json = await response.json();
      return schema.parse(json);
    } catch (e) {
      if (e instanceof ZodError) {
        throw new ResponseValidationError(e, requestUrl, JSON.stringify(json));
      }
      if (isSyntaxError(e)) {
        throw ClientError.SyntaxError(e, requestUrl);
      }
      throw ClientError.Unknown(e, requestUrl);
    }
  }
  async raw(endpoint, method, schema, properties = {}) {
    const requestUrl = this.url(endpoint);
    const response = await this.request(requestUrl, {
      method,
      ...properties.body && { body: properties.body }
    });
    await this.parseResponse(requestUrl, response);
    const body = await this.serializeResponseBody(requestUrl, response, schema);
    return {
      // we have to create a new Response object here as the original is immutable,
      // returning it would prevent Astro or other middleware from amending headers.
      // we must also deserialize the body to ensure it matches content length
      response: new Response(JSON.stringify(body), response),
      body
    };
  }
  async get(endpoint, schema, query = {}) {
    const requestUrl = this.url(endpoint, query);
    const response = await this.request(requestUrl, { method: "GET" });
    await this.parseResponse(requestUrl, response);
    return this.serializeResponseBody(requestUrl, response, schema);
  }
  async post(endpoint, schema, body) {
    const requestUrl = this.url(endpoint);
    const response = await this.request(requestUrl, {
      method: "POST",
      body
    });
    await this.parseResponse(requestUrl, response);
    return this.serializeResponseBody(requestUrl, response, schema);
  }
  async patch(endpoint, schema, body) {
    const requestUrl = this.url(endpoint);
    const response = await this.request(requestUrl, {
      method: "PATCH",
      body
    });
    await this.parseResponse(requestUrl, response);
    return this.serializeResponseBody(requestUrl, response, schema);
  }
  async put(endpoint, schema, body) {
    const requestUrl = this.url(endpoint);
    const response = await this.request(requestUrl, {
      method: "PUT",
      body
    });
    await this.parseResponse(requestUrl, response);
    return this.serializeResponseBody(requestUrl, response, schema);
  }
  async delete(endpoint, schema) {
    const requestUrl = this.url(endpoint);
    const response = await this.request(requestUrl, {
      method: "DELETE"
    });
    await this.parseResponse(requestUrl, response);
    return this.serializeResponseBody(requestUrl, response, schema);
  }
};

// src/schema.sublimator.ts
import { z as z2 } from "zod";
var errorSchema = z2.object({
  message: z2.string(),
  details: z2.array(
    z2.object({
      type: z2.string().optional(),
      message: z2.string()
    })
  ).optional()
});
var errorsSchema = z2.union([
  z2.object({
    errors: z2.array(errorSchema)
  }),
  errorSchema
]);
var prismicDocumentSchema = z2.object({
  id: z2.string(),
  uid: z2.string().nullish(),
  url: z2.string().nullish(),
  type: z2.string(),
  href: z2.string(),
  tags: z2.array(z2.string()),
  first_publication_date: z2.string().datetime({ offset: true }),
  last_publication_date: z2.string().datetime({ offset: true }),
  slugs: z2.array(z2.string()),
  linked_documents: z2.array(z2.unknown()),
  lang: z2.string().regex(/[a-z]{2}(?:-[a-z]{2})/i),
  alternate_languages: z2.array(
    z2.object({
      id: z2.string(),
      type: z2.string(),
      lang: z2.string().regex(/[a-z]{2}(?:-[a-z]{2})/i),
      uid: z2.string().nullish()
    })
  ),
  data: z2.unknown()
});
var solidusProductSchema = z2.object({
  sublimator_attributes: z2.object({
    lowest_price: z2.object({ amount: z2.string(), formatted: z2.string() }),
    muse_slug: z2.string(),
    master_sku: z2.string(),
    category: z2.string(),
    sales: z2.object({
      last_day: z2.number().nullable(),
      last_3_days: z2.number().nullable(),
      last_7_days: z2.number().nullable(),
      last_30_days: z2.number().nullable()
    })
  }),
  id: z2.number(),
  name: z2.string(),
  description: z2.string(),
  available_on: z2.string().datetime({ offset: false }),
  slug: z2.string(),
  meta_description: z2.string(),
  meta_keywords: z2.string(),
  shipping_category_id: z2.number(),
  taxon_ids: z2.array(z2.number()),
  meta_title: z2.string(),
  total_on_hand: z2.number().nullable(),
  price: z2.string(),
  display_price: z2.string(),
  option_types: z2.array(
    z2.object({
      id: z2.number(),
      name: z2.string(),
      presentation: z2.string(),
      position: z2.number()
    })
  ),
  product_properties: z2.array(
    z2.object({
      id: z2.number(),
      product_id: z2.number(),
      property_id: z2.number(),
      value: z2.string(),
      property_name: z2.string()
    })
  ),
  classifications: z2.array(
    z2.object({
      taxon_id: z2.number(),
      position: z2.number(),
      taxon: z2.object({
        id: z2.number(),
        name: z2.string(),
        pretty_name: z2.string(),
        permalink: z2.string(),
        parent_id: z2.number(),
        taxonomy_id: z2.number(),
        taxons: z2.array(z2.unknown())
      })
    })
  )
});
var eagleVariantSchema = z2.object({
  id: z2.number(),
  sku: z2.string(),
  weight: z2.string().nullable(),
  height: z2.string().nullable(),
  width: z2.string().nullable(),
  depth: z2.string().nullable(),
  deleted_at: z2.string().nullable(),
  is_master: z2.boolean(),
  product_id: z2.number(),
  cost_price: z2.string().nullable(),
  position: z2.number(),
  cost_currency: z2.string(),
  track_inventory: z2.boolean(),
  tax_category_id: z2.number().nullable(),
  updated_at: z2.string(),
  created_at: z2.string(),
  frontend_viewable: z2.boolean(),
  submit_by_variant_sku: z2.boolean(),
  submission_sku_override: z2.string().nullable(),
  stock_line_group_id: z2.number().nullable(),
  price: z2.object({ amount: z2.string(), formatted: z2.string() })
});
var eagleProductSchema = z2.object({
  id: z2.number(),
  name: z2.string(),
  description: z2.string(),
  available_on: z2.string().nullable(),
  deleted_at: z2.null(),
  slug: z2.string(),
  meta_description: z2.string().nullable(),
  meta_keywords: z2.string().nullable(),
  tax_category_id: z2.number().nullable(),
  shipping_category_id: z2.number(),
  created_at: z2.string(),
  updated_at: z2.string(),
  hero: z2.boolean(),
  promotionable: z2.boolean(),
  meta_title: z2.string().nullable(),
  category: z2.string(),
  is_visible_to_psp: z2.boolean(),
  muse_slug: z2.string(),
  discontinue_on: z2.null(),
  variants: z2.array(eagleVariantSchema)
});
var eagleProductsSchema = z2.array(eagleProductSchema);
var contentPrismicSchema = z2.object({
  data: prismicDocumentSchema
});
var productSchema = z2.object({
  data: z2.object({
    solidus: solidusProductSchema,
    prismic: prismicDocumentSchema,
    canvasPrismic: prismicDocumentSchema.nullable(),
    eagle: eagleProductsSchema
  })
});
var marketplaceSchema = z2.object({
  data: z2.array(
    z2.object({
      identifier: z2.string(),
      iso: z2.string(),
      currency: z2.string(),
      name: z2.string(),
      locale: z2.string(),
      contentLocales: z2.array(z2.string()),
      localisedName: z2.string(),
      algoliaIndex: z2.string().optional()
    })
  )
});

// src/client.sublimator.ts
var PrismicCookie = cookie.preview;
var SublimatorClient = class extends ApiClient {
  marketplace;
  prismicRepo;
  prismicRef;
  constructor(options) {
    super(options);
    this.setHeader("X-Wonderbly-Marketplace-Id", options.marketplace);
    this.marketplace = options.marketplace;
    this.prismicRepo = options.prismicRepo;
    this.prismicRef = options.prismicRef;
  }
  validateErrorResponse(json) {
    const result = errorsSchema.parse(json);
    let error;
    if ("errors" in result) {
      error = result.errors[0];
    } else {
      error = result;
    }
    const message = error.details ? error.details.reduce((accum, curr) => `${accum}
${curr.message}`, "") : error.message;
    return { message, error: result };
  }
  async getPrismicContent(documentType, uid = "") {
    const endpoint = `content/prismic/${this.prismicRepo}/documents/${documentType}${uid ? "/" + uid : ""}`;
    const response = await this.get(endpoint, contentPrismicSchema, {
      prismic_ref: this.prismicRef
    });
    return response;
  }
  async getProduct(slug) {
    const endpoint = `products/${slug}`;
    const response = await this.get(endpoint, productSchema, {
      prismic_ref: this.prismicRef
    });
    return response;
  }
  async getMarketplaces() {
    return await this.get("marketplaces", marketplaceSchema);
  }
};

// src/schema.eagle.ts
import { z as z3 } from "zod";
var eagleErrorSchema = z3.object({
  errors: z3.array(
    z3.object({
      code: z3.string(),
      detail: z3.nullable(z3.string()),
      status: z3.string()
    })
  )
});
var cartCountResponseSchema = z3.object({
  data: z3.object({
    count: z3.number()
  })
});
var pricingSchema = z3.object({
  amount: z3.string(),
  formatted: z3.string()
});
var lineItemPriceSchema = pricingSchema.extend({
  breakdown: z3.object({
    promo: pricingSchema.optional()
  }).optional()
});
var totalPriceSchema = pricingSchema.extend({
  breakdown: z3.object({
    subtotal: pricingSchema,
    shipping: pricingSchema.optional(),
    discount: pricingSchema.extend({
      breakdown: z3.object({
        credit: pricingSchema.optional(),
        promo: pricingSchema.optional()
      })
    }).optional(),
    tax: pricingSchema.optional(),
    taxIncluded: pricingSchema.optional()
  })
});
var addressSchema = z3.object({
  firstname: z3.string(),
  lastname: z3.string().optional(),
  phone: z3.optional(z3.string()),
  address1: z3.string(),
  address2: z3.optional(z3.string()),
  city: z3.string(),
  country: z3.string(),
  state: z3.optional(z3.string()),
  zipcode: z3.optional(z3.string())
});
var shippingSchema = z3.object({
  delivery: z3.object({
    earliest: z3.string(),
    latest: z3.string()
  }),
  method: z3.object({
    name: z3.string(),
    type: z3.string()
  }),
  tracking: z3.object({
    url: z3.optional(z3.string()),
    code: z3.optional(z3.string())
  }),
  address: addressSchema
});
var orderResponseSchema = z3.object({
  data: z3.object({
    number: z3.string(),
    state: z3.string(),
    // paused|canceled|delivered|shipped|making
    isOpen: z3.boolean(),
    processAt: z3.string().datetime().optional(),
    items: z3.array(
      z3.object({
        id: z3.number(),
        type: z3.string(),
        variantSku: z3.string(),
        format: z3.object({
          id: z3.string(),
          identifier: z3.nullable(z3.string())
        }),
        productSlug: z3.string(),
        productSku: z3.string(),
        customisation: z3.any(),
        components: z3.array(
          z3.object({
            id: z3.string()
          })
        ),
        price: lineItemPriceSchema,
        coverImage: z3.object({
          url: z3.string(),
          isMuseImage: z3.boolean()
        }).optional()
      })
    ),
    shipping: shippingSchema,
    price: totalPriceSchema
  })
});
var consentResponseSchema = z3.object({
  id: z3.number(),
  preferences: z3.record(z3.enum(["accepted", "rejected"])),
  provider_consent_id: z3.string(),
  provider: z3.string(),
  user_id: z3.number().nullable(),
  created_at: z3.string().datetime(),
  updated_at: z3.string().datetime()
});
var userDataResponseSchema = z3.object({
  firstname: z3.string().optional(),
  lastname: z3.string().optional(),
  email: z3.string(),
  credit: pricingSchema,
  addresses: z3.array(addressSchema)
});
var updateUserDataSchema = z3.object({
  firstname: z3.string(),
  lastname: z3.string().optional()
});
var ordersResponseSchema = z3.object({
  data: z3.array(
    z3.object({
      number: z3.string(),
      processAt: z3.string().optional(),
      state: z3.string(),
      isOpen: z3.boolean(),
      items: z3.array(
        z3.object({
          id: z3.number(),
          productSku: z3.string(),
          customisation: z3.any()
        })
      ),
      shipping: z3.object({
        delivery: z3.object({
          earliest: z3.string(),
          latest: z3.string()
        }),
        tracking: z3.object({
          url: z3.optional(z3.string()),
          code: z3.optional(z3.string())
        })
      }),
      price: pricingSchema
    })
  )
});
var resetPasswordFlowSchema = z3.object({ message: z3.string() });
var cartResponseSchema = z3.object({
  data: z3.object({
    order: z3.object({
      id: z3.string(),
      numeric_id: z3.number(),
      state: z3.string(),
      promoCode: z3.string().optional(),
      items: z3.array(
        z3.object({
          id: z3.number(),
          sku: z3.string(),
          productSlug: z3.string(),
          productSku: z3.string(),
          customisations: z3.any(),
          format: z3.object({
            id: z3.string().optional(),
            identifier: z3.nullable(z3.string())
          }).optional(),
          discountedAmount: pricingSchema.optional(),
          price: pricingSchema,
          savings: z3.object({
            price: pricingSchema,
            percent: z3.string()
          }).optional(),
          promoTotal: pricingSchema.optional(),
          giftWrap: z3.boolean(),
          components: z3.array(
            z3.object({
              id: z3.string()
            })
          ).optional(),
          type: z3.string(),
          coverImage: z3.object({
            url: z3.string(),
            isMuseImage: z3.boolean()
          }).optional(),
          parentLineItemId: z3.number().optional()
        })
      ),
      totals_v2: z3.object({
        order: pricingSchema,
        items: pricingSchema,
        shipping: pricingSchema.optional(),
        discount: pricingSchema.extend({
          shipping_discount: pricingSchema,
          discount_ex_shipping_discount: pricingSchema
        }).optional(),
        tax: pricingSchema.optional(),
        tax_included: pricingSchema.optional()
      }),
      store_credits: pricingSchema.optional(),
      is_paypal_approved: z3.boolean()
    })
  })
});
var paymentMethodsResponseSchema = z3.object({
  data: z3.array(z3.object({ id: z3.string() }))
});
var paymentMethodSessionResponseSchema = z3.object({
  orderId: z3.string()
});
var submitPaypalOrderResponseSchema = z3.object({
  orderId: z3.string()
});
var expressCreateResponseSchema = z3.object({});
var expressLineItemsResponseSchema = z3.array(
  z3.object({
    amount: z3.string(),
    currency: z3.string(),
    name: z3.string(),
    quantity: z3.number()
  })
);
var shippingMethodsMethodSchema = z3.object({
  id: z3.number(),
  name: z3.string(),
  type: z3.string(),
  carrier: z3.string()
});
var shippingMethodsDeliverySchema = z3.object({
  earliest: z3.string(),
  latest: z3.string()
});
var shippingMethodsResponseSchema = z3.object({
  checkout: z3.object({
    id: z3.number(),
    number: z3.string(),
    state: z3.string(),
    email: z3.string().optional(),
    shippingAddress: z3.object({
      zipcode: z3.string(),
      country: z3.string()
    }),
    selectedShipment: z3.object({
      discounted_shipping_rate: z3.nullable(pricingSchema),
      id: z3.number(),
      selected: z3.boolean(),
      cost: pricingSchema,
      shipping_duration: z3.number(),
      production_duration: z3.number(),
      method: shippingMethodsMethodSchema,
      delivery: shippingMethodsDeliverySchema
    })
  }),
  shipment: z3.object({
    number: z3.string(),
    shipping_rates: z3.array(
      z3.object({
        id: z3.number(),
        selected: z3.boolean(),
        cost: pricingSchema,
        shipping_duration: z3.number(),
        production_duration: z3.number(),
        method: shippingMethodsMethodSchema,
        delivery: shippingMethodsDeliverySchema
      })
    )
  })
});
var shippingMethodsUpdateResponseSchema = z3.object({});
var createPaymentIntentResponseSchema = z3.object({
  client_secret: z3.string(),
  metadata: z3.object({
    order_number: z3.string()
  })
});

// src/client.eagle.ts
var EagleClient = class extends ApiClient {
  // sets anonymous as the default auth type until we look at the cookies
  authType = "anonymous";
  constructor(options) {
    super(options);
    this.setHeader("Content-Type", "application/json");
    this.setHeader("LMN_LOCALE", options.marketplace.locale);
    this.setHeader("LMN_CURRENCY", options.marketplace.currency);
    this.setHeader("LMN_COUNTRY_ISO", options.marketplace.iso);
    if (options.eagleAuthCookies.guestToken) {
      this.authType = "guest";
      this.setHeader("LMN_GUEST_TOKEN", options.eagleAuthCookies.guestToken);
    }
    if (options.eagleAuthCookies.restricted_user_id) {
      this.authType = "restricted_user";
      this.setHeader(
        "LMN_RESTRICTED_USER",
        options.eagleAuthCookies.restricted_user_id
      );
    }
    if (options.eagleAuthCookies.roles) {
      this.authType = "session";
      this.setCookie("_eagle_session", options.eagleAuthCookies._eagle_session);
    }
  }
  /**
   * This method is used when we want to ensure that an auth type is being used.
   * The majority of the time we should rely on Eagle to handle its own authentication.
   * Only to be used when frontend need is different from Eagle auth checks.
   */
  customAuthCheck(type) {
    if (!type.includes(this.authType)) {
      throw new Error(
        `EagleClient: This endpoint requires an auth type of: ${type.join(
          ", "
        )} but was provided with: ${this.authType}`
      );
    }
  }
  validateErrorResponse(json) {
    const result = eagleErrorSchema.parse(json);
    return {
      message: result.errors[0].detail ?? "",
      error: result
    };
  }
  async getCartCount() {
    const result = await this.get("cart/count", cartCountResponseSchema);
    return result.data;
  }
  async getCartDetails() {
    const result = await this.get("cart", cartResponseSchema);
    return result.data;
  }
  async deleteCartItem(itemId) {
    const result = await this.delete(
      `cart/items/${itemId}`,
      cartResponseSchema
    );
    return result.data;
  }
  async applyDiscount(code) {
    const result = await this.post(
      "cart/promotion",
      cartResponseSchema,
      JSON.stringify({ code })
    );
    return result.data;
  }
  async deleteDiscount() {
    const result = await this.delete("cart/promotion", cartResponseSchema);
    return result.data;
  }
  async getOrderDetails(orderNumber) {
    const result = await this.get(`orders/${orderNumber}`, orderResponseSchema);
    return result.data;
  }
  // TODO: Other methods in EagleClient to return definite types rather than inferences
  async getUserData() {
    this.customAuthCheck(["session"]);
    const data = await this.get(`user`, userDataResponseSchema);
    return data;
  }
  async updateUserData(payload) {
    this.customAuthCheck(["session"]);
    const data = await this.patch(`user`, userDataResponseSchema, payload);
    return data;
  }
  async triggerResetPasswordFlow(payload) {
    const data = await this.post(
      `user/reset_password_flow`,
      resetPasswordFlowSchema,
      payload
    );
    return data;
  }
  async getRecentOrdersDetails() {
    const result = await this.get(`orders/recent`, ordersResponseSchema);
    return result.data;
  }
  async getCompletedOrdersDetails() {
    const result = await this.get(`orders/completed`, ordersResponseSchema);
    return result.data;
  }
  async updateShippingAddress(orderNumber, payload) {
    const result = await this.put(
      `orders/${orderNumber}/shipping`,
      orderResponseSchema,
      payload
    );
    return result;
  }
  async getPaymentMethods() {
    const result = await this.get(
      "/payment_methods",
      paymentMethodsResponseSchema
    );
    return result.data;
  }
  // TODO: expand `paymentMethod` to other appropriate types
  async getPaymentMethodSession(paymentMethod) {
    const result = await this.post(
      `checkout/payment/${paymentMethod}`,
      paymentMethodSessionResponseSchema,
      ""
    );
    return result;
  }
  // require raw request to relay response headers
  async submitPaypalOrder(payload) {
    const { response } = await this.raw(
      "checkout/payment/paypal_checkout/order_approved",
      "POST",
      submitPaypalOrderResponseSchema,
      {
        body: payload
      }
    );
    return response;
  }
  async createExpressCheckout(payload) {
    const result = await this.post(
      "checkout/shipping/express",
      expressCreateResponseSchema,
      payload
    );
    return result;
  }
  async deleteExpressCheckout() {
    const result = await this.delete(
      "checkout/shipping/express",
      expressCreateResponseSchema
    );
    return result;
  }
  async getShippingMethods() {
    const result = await this.get(
      "checkout/shipping/methods",
      shippingMethodsResponseSchema
    );
    return result;
  }
  async updateShippingMethod(payload) {
    const result = await this.post(
      "checkout/shipping/methods",
      shippingMethodsUpdateResponseSchema,
      payload
    );
    return result;
  }
  async getExpressLineItems() {
    const result = await this.get(
      "checkout/payment/get_express_line_items",
      expressLineItemsResponseSchema
    );
    return result;
  }
  // require raw request to relay response headers
  async createPaymentIntent(payload) {
    const { response } = await this.raw(
      "checkout/payment/create_express_payment_intent",
      "POST",
      createPaymentIntentResponseSchema,
      {
        body: payload
      }
    );
    return response;
  }
  async updateConsent(payload) {
    return await this.put(
      "consent",
      consentResponseSchema,
      JSON.stringify(payload)
    );
  }
};
export {
  ClientError,
  EagleClient,
  HttpError,
  NetworkError,
  PrismicCookie,
  ResponseValidationError,
  SublimatorClient
};
