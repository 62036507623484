import { pushEvent } from "@wbly/common";
import type { DataLayer } from "@wbly/common";

export const trackValuePropsCtaClick = (ctaLink: string) =>
  pushEvent(
    window?.dataLayer as DataLayer[],
    `homepage`,
    `value_props`,
    `click_cta_button`,
    `Redirected to ${ctaLink}`
  );
