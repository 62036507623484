import { pushEvent } from "@wbly/common";
import type { DataLayer } from "@wbly/common";

export const trackFeatureTilesClick = (
  featureTilesRowId: string,
  featureTilesIndex: number
) =>
  pushEvent(
    window?.dataLayer as DataLayer[],
    `homepage`,
    `${featureTilesRowId}`,
    `Card position: ${featureTilesIndex}`
  );
