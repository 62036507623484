import { asText } from "@prismicio/helpers";
import { Button } from "@wbly/ui";
import { type SwitchCountrySlice } from "@wbly/data-storefront";
import { Close } from "../Header/Icons";
import styles from "./SwitchCountry.module.css";
import { useSwitchCountry } from "./useSwitchCountry";

type SwitchCountryProps = {
  marketplace: string;
} & SwitchCountrySlice;

const {
  container,
  modalTitle,
  modalText,
  closeButton,
  usCtaButton,
  switchCtaButton,
  header,
} = styles;

export function SwitchCountry({
  title,
  content,
  usCtaTitle,
  switchCtaTitle,
  marketplace,
}: SwitchCountryProps) {
  const {
    handleClose,
    handleNavigateToCountry,
    handleStayInCountry,
    openDialog,
  } = useSwitchCountry({ marketplace });

  if (!openDialog) {
    return null;
  }

  return (
    <div className={container}>
      <header className={header}>
        <button
          className={closeButton}
          aria-label="close modal"
          data-testid="drawer-close-button"
          onClick={handleClose}
        >
          <Close />
        </button>
      </header>
      <div className="content">
        <h1 className={modalTitle}>{title}</h1>
        <p className={modalText}>{asText(content)}</p>

        <Button
          onClick={handleNavigateToCountry}
          outline={true}
          fullWidth={true}
          variant="secondary"
          className={switchCtaButton}
        >
          {asText(switchCtaTitle)}
        </Button>
        <Button
          onClick={handleStayInCountry}
          fullWidth={true}
          variant="secondary"
          className={usCtaButton}
        >
          {asText(usCtaTitle)}
        </Button>
      </div>
    </div>
  );
}
