import { Carousel } from "@wbly/ui";
import { useEffect, type FC } from "react";
import type { HeroSlice } from "@wbly/data-storefront";
import { Slide } from "./Slide";
import styles from "./Hero.module.css";
import { ArrowIcon } from "./ArrowIcon";

const { carouselContainer, arrowRight } = styles;

type HeroProps = {
  slides: HeroSlice["body"]["items"];
};

export const HeroComponent: FC<HeroProps> = (props) => {
  const { slides } = props;

  useEffect(() => {
    const placeholderSlide = document.querySelector(
      '[data-placeholder="true"]'
    );

    if (placeholderSlide) {
      (placeholderSlide as HTMLDivElement).style.pointerEvents = "none";

      (placeholderSlide as HTMLDivElement).style.display = "none";
    }
  }, []);

  const heroSlides = slides.map((slide) => {
    const uid = `${slide.theme}-${slide.description.match(/\b\w+/)}`;

    return (
      <Carousel.Slide key={uid}>
        <Slide {...slide} />
      </Carousel.Slide>
    );
  });

  return (
    <div className={carouselContainer}>
      <Carousel
        displayAffix={true}
        dotsNav={true}
        id="hero-slider"
        gap={10}
        peek={{
          before: 0,
          after: 0,
        }}
        breakpoints={{
          9999: {
            animationDuration: 0,
            rewindDuration: 0,
            dragThreshold: false,
            swipeThreshold: 40,
            dotsNav: true,
          },
          777: {
            animationDuration: 300,
            dragThreshold: false,
            swipeThreshold: 40,
            dotsNav: true,
            peek: {
              before: 0,
              after: 30,
            },
          },
        }}
      >
        <Carousel.Affix position="left" action="prev">
          <span>
            <ArrowIcon />
          </span>
        </Carousel.Affix>
        {heroSlides}
        <Carousel.Affix position="right" action="next">
          <span className={arrowRight}>
            <ArrowIcon />
          </span>
        </Carousel.Affix>
      </Carousel>
    </div>
  );
};

export default HeroComponent;
